import React, {useState} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Alert,
    Autocomplete,
    Box, Button,
    Card,
    CardContent, CircularProgress,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import useCheckIsMobile from "../hooks/useCheckIsMobile";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {callBackendAPI, getLabelValue, logError, verifyEmail} from "../utils";
import {BackendResponse, IError, TItem} from "../types";
import {CONSTANTS} from "../constants";
import {tokens} from "../theme";

interface IMSIC {
    code: string;
    description: string;
    msic_category_reference: string;
}

type ISuccess = {
    success: boolean;
    message: string;
}

const colors = tokens();
const TaxSettings = () => {

    const isMobile: boolean = useCheckIsMobile();
    const lang: string = useSelector((state: RootState) => state.home).lang;

    const [merchantCode, setMerchantCode] = useState<string>('');
    const [tin, setTin] = useState<string>("");
    const [idValue, setIdValue] = useState<string>("");
    const [error, setError] = useState<IError>({error: false, message: ''});
    const [loading, setLoading] = useState<boolean>(false);
    const [idType, setIdType] = useState<string>("");
    const [sst, setSst] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>("");
    const [tourismTax, setTourismTax] = useState<string | undefined>(undefined);
    const [validated, setValidated] = useState<boolean>(false);
    const [msicCodes, setMsicCodes] = useState<IMSIC | any>([]);
    const [searchValue, setSearchValue] = useState<IMSIC | null>(null);
    const [success, setSuccess] = useState<ISuccess>({success: false, message: ''});
    const ID_TYPES: TItem[] = lang === 'en' ? CONSTANTS.IDENTIFICATION_TYPES_EN : CONSTANTS.IDENTIFICATION_TYPES_BM;
    const [taxPayerID, setTaxPayerID] = useState<number>(-1);
    const [prefix, setPrefix] = useState<string>("");

    const resetInputs = () => {
        if (validated)
            setValidated(false);
        if (sst !== '')
            setSst('');
        if (tourismTax !== '')
            setTourismTax('');
        if (email !== '')
            setEmail('');
        if (searchValue !== null)
            setSearchValue(null);
        if (error.error)
            setError({ error: false, message: ''});
        if (loading)
            setLoading(false);
        if (taxPayerID)
            setTaxPayerID(-1);
        if (success.success)
            setSuccess({success: false, message: ''});
    }

    const submitData = async () => {
        try {
            if (error.error)
                setError({ error: false, message: '' });
            // if (searchValue === null) {
            //     return setError({ error: true, message: "Please select a MSIC Code" });
            // }
            if (!verifyEmail(email))
                return setError({ error: true, message: 'Please enter a valid email address' });

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            const input: any = {
                merchantCode,
                tin,
                idtype: idType,
                idvalue: idValue,
                tIdx: taxPayerID,
                msic: searchValue,
                sst: sst,
                ttregno: tourismTax,
                email,
                tprefix: prefix,
            }
            const response: BackendResponse | undefined = await callBackendAPI('einvoice/taxsettings/create', 'POST', headers, input);
            if (response === undefined) {
                setLoading(false);
                return setError({ error: true, message: `001: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: `002: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.results === undefined) {
                setLoading(false);
                return setError({ error: true, message: `003: ${getLabelValue(lang, 'home_exception')}`});
            }
            const results: any = response.results;
            if (results?.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.message || `004: ${getLabelValue(lang, 'home_exception')}`
                });
            }
            setLoading(false);
            return setSuccess({ success: true, message: results?.message });
        } catch (e) {
            logError('submitData Tax settings', e);
            setLoading(false);
            return setError({ error: true, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    const handleSubmit = async () => {
        try {
            if (error.error)
                setError({ error: false, message: '' });
            if (merchantCode === '') {
                return setError({error: true, message: 'Please enter Agent / eRemit ID/Merchant Code'});
            }
            if (prefix === '') {
                return setError({error: true, message: 'Please select a TIN prefix'});
            }
            if (tin === '') {
                return setError({error: true, message: 'Please enter Tax Identification ID'});
            }
            if (tin.length < 5) {
                return setError({error: true, message: 'TIN must be at least 5 digits'});
            }
            if (idType === '') {
                return setError({error: true, message: 'Please Choose your Identification ID Type'});
            }
            if (idValue === '') {
                return setError({error: true, message: 'Please enter your MyKad/ Identification Number'});
            }

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            const input: any = {
                merchantCode,
                tin,
                idtype: idType,
                idvalue: idValue,
                tprefix: prefix
            }
            const response: BackendResponse | undefined = await callBackendAPI('einvoice/taxsettings/validate', 'POST', headers, input);
            if (response === undefined) {
                setLoading(false);
                return setError({ error: true, message: `001: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: `002: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.results === undefined) {
                setLoading(false);
                return setError({ error: true, message: `003: ${getLabelValue(lang, 'home_exception')}`});
            }
            const results: any = response.results;
            if (results?.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.message || `004: ${getLabelValue(lang, 'home_exception')}`
                });
            }
            setLoading(false);
            setMsicCodes(results?.msic || []);
            setTaxPayerID(results?.tIdx);
            if (results?.msic_code && results?.msic_code !== '') {
                const msicCode= results?.msic.find((i: any) => i.code === results?.msic_code);
                if (msicCode !== undefined)
                    setSearchValue(msicCode);
            }
            if (results?.email)
                setEmail(results?.email || '');
            if (results?.sst)
                setSst(results?.sst || '');
            return setValidated(true);
        } catch (e) {
            logError('handleSubmit Tax settings', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    const renderInputs = () => {
        return (
            <>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={6}>
                        <Typography variant='body2' my={0.5}>
                            Agent / eRemit ID / Merchant Code
                        </Typography>
                        <TextField
                            fullWidth
                            id="merchantCode"
                            variant="outlined"
                            value={merchantCode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (!isNaN(Number(event.target.value))) {
                                    setMerchantCode(event.target.value);
                                    resetInputs();
                                }
                            }}
                            inputProps={{
                                inputMode: 'numeric'
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={6} container spacing={2}>
                        <Grid2 xs={12} md={3}>
                            <FormControl fullWidth>
                                <Typography variant='body2' my={0.5}>
                                    TIN Prefix
                                </Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={prefix}
                                    label="Age"
                                    onChange={(e) => setPrefix(e.target.value)}
                                >
                                    {[CONSTANTS.TIN_PREFIX.map((p, i) => {
                                        return (
                                            <MenuItem value={p?.value}>{p.label}</MenuItem>
                                        )
                                    })]}
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12} md={9}>
                            <Typography variant='body2' my={0.5}>
                                Tax Information Number (TIN)
                            </Typography>
                            <TextField
                                fullWidth
                                id="tin"
                                variant="outlined"
                                value={tin}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!isNaN(Number(event.target.value))) {
                                        setTin(event.target.value);
                                        resetInputs();
                                    }
                                }}
                                helperText={getLabelValue(lang, 'tax_settings_tin_helper')}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={6}>
                        <Typography variant='body2' my={0.5}>
                            Identification Type
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId={'idtype-label'}
                                id={`idtype-label-select`}
                                value={idType}
                                onChange={(event: SelectChangeEvent) => {
                                    setIdType(event.target.value);
                                    resetInputs();
                                }}
                            >
                                {ID_TYPES?.map((i: TItem,v:number) => {
                                    return (
                                        <MenuItem key={v} value={i?.value}>{i?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <Typography variant='body2' my={0.5}>
                            MyKad/ Identification Number
                        </Typography>
                        <TextField
                            fullWidth
                            id="idValue"
                            variant="outlined"
                            value={idValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setIdValue(event.target.value);
                                resetInputs();
                            }}
                            helperText={getLabelValue(lang, 'tax_settings_id_helper')}
                        />
                    </Grid2>
                </Grid2>

                {validated && (
                    <>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} md={12}>
                                <Typography variant='body2' my={0.5}>
                                    Malaysia Standard Industrial Classification (MSIC) Code
                                </Typography>
                                <Autocomplete<IMSIC>
                                    sx={{ mt: 2}}
                                    value={searchValue}
                                    onChange={(event: React.SyntheticEvent, newValue: IMSIC | null) => {
                                        setSearchValue(newValue);
                                    }}
                                    options={msicCodes}
                                    getOptionLabel={(option: IMSIC) => `${option?.description} (${option?.code})` }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search and select an MSIC Code"
                                            variant="outlined"
                                        />
                                    )}
                                    filterOptions={(options: IMSIC[], { inputValue }: { inputValue: string }) =>
                                        options.filter((option) =>
                                            option?.description?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                            option?.code?.toLowerCase().includes(inputValue.toLowerCase())
                                        )
                                    }
                                    renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: IMSIC) => (
                                        <li key={option.code} {...props}>
                                            <div>
                                                <strong>{`${option.description} (${option.code})`}</strong>
                                            </div>
                                        </li>
                                    )}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} md={6}>
                                <Typography variant='body2' my={0.5}>
                                    SST Registration Number (optional)
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="sst"
                                    variant="outlined"
                                    value={sst}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSst(event.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 xs={12} md={6}>
                                <Typography variant='body2' my={0.5}>
                                    Tourism Tax Registration No. (optional)
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="tourismTax"
                                    variant="outlined"
                                    value={tourismTax}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setTourismTax(event.target.value);
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} md={6}>
                                <Typography variant='body2' my={0.5}>
                                    Email (optional)
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </>
        )
    }

    return (
        <Box
            sx={{
                width: '100vw',
                minHeight: '100vh',
                backgroundImage: 'url("/images/background.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    width: isMobile ? '85%' :'60%',
                    padding: isMobile ? '10px' : '20px',
                    mt: isMobile ? 20 : 12,
                    //mb: isMobile ? 50 : 12
                }}
            >
                <CardContent>
                    <Grid2
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mb={4}
                    >
                        <img
                            src={require('../assets/mmbizlogo.png')}
                            style={{ width: isMobile ? '90%' : '30%' }}
                            alt="Mobile Money"
                        />
                    </Grid2>
                    <Grid2
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ px: 5, py: 2}}
                    >
                        <Typography variant="body2" color="text.secondary">
                            {getLabelValue(lang, 'tax_settings_description')}
                        </Typography>
                    </Grid2>
                    {renderInputs()}
                    <Grid2
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{my: 3,mx: 5}}
                        container
                    >
                        <Grid2
                            xs={12} md={6}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Button
                                variant="contained"
                                fullWidth={isMobile}
                                sx={styles(isMobile).submitButtonStyle}
                                onClick={async () => validated ? await submitData() : await handleSubmit() }
                                color={"success"}
                                disabled={loading}
                                endIcon={loading ? <CircularProgress size={20} /> : null}
                            >
                                {validated ? 'SUBMIT' : 'VERIFY'}
                            </Button>
                        </Grid2>
                    </Grid2>
                    {error.error &&
                        <Box>
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => {
                                    setError({ error: false, message: ''});
                                    resetInputs()
                                }}
                            >
                                {error.message}
                            </Alert>
                        </Box>
                    }
                    {success.success &&
                        <Box>
                            <Alert
                                variant="filled"
                                severity="success"
                                action={
                                    <Button
                                        color="warning"
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                            setSuccess({ success: false, message: ''});
                                            resetInputs();
                                            setMerchantCode('');
                                            setTin('');
                                            setIdValue('');
                                            setIdType('');
                                            setPrefix('');
                                        }}
                                    >
                                        DONE
                                    </Button>
                                }
                            >
                                {success.message}
                            </Alert>
                        </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxContainer: {
        display: 'flex',
        py: isMobile ? 0 : 5,
        justifyContent: 'center',
        minHeight: '100%',
        backgroundColor: isMobile ? colors.white[100] : '#F6F6F6',
    },
    submitButtonStyle: {
        px: 5,
        py: isMobile ? 2 :1
    },
    inputGridStyles: {
        my: 3, mx: isMobile ? 5 : 8
    },
})

export default TaxSettings;
