export const EN_LANG_CONFIG: { [key: string]: any } = {
    'home_screen_header': 'Welcome',
    'home_screen_description': 'Log into your account to use the MMUnity App',
    'home_tin': 'Tax Information Number (TIN)',
    'home_idtype': 'Choose an Identification Type',
    'home_idvalue': 'Identification Number',
    'home_refno': 'MM Transaction No / Invoice No',
    'home_submit_button': 'SEARCH',
    'home_exception': 'OOPS SOMETHING WENT WRONG',
    'home_tin_required': 'Tax Information Number is required',
    'home_idtype_required': 'Please Choose an Identification Type',
    'home_idvalue_required': 'Identification Number is required',
    'home_refno_required': 'MM Reference No is required',
    'home_back_button': 'Back',
    'home_claim_button': 'Claim',
    'home_cancel_button': 'Cancel',
    'claim_button': 'Claim Invoice',
    'claim_disclaimer': 'Disclaimer',
    'claim_disclaimer_desc': 'NOTE: This invoice has not yet been submitted to IRBM.',
    'claim_check_invoice': 'Check Invoice',
    'tax_settings_description': 'Please provide your TIN No. and provide the necessary details.',
    'tax_settings_tin_helper': 'Enter at least 5 digits',
    'tax_settings_id_helper': 'For BRN, please enter a valid identification number',
}
