export const CONSTANTS = {
    IDENTIFICATION_TYPES_EN: [
        {value: "NRIC", label: "Identification Card No."},
        {value: "PASSPORT", label: "Passport No."},
        {value: "ARMY", label: "Army No."},
        {value: "BRN", label: "Business Registration No."},
    ],
    IDENTIFICATION_TYPES_BM: [
        {value: "NRIC", label: "No. Kad Pengenalan"},
        {value: "PASSPORT", label: "No. Passport"},
        {value: "ARMY", label: "No. Tentera"},
        {value: "BRN", label: "Business Registration No."},
    ],
    TIN_PREFIX: [
        {value: "IG", label: "IG"},
        {value: "C", label: "C"},
        {value: "CS", label: "CS"},
        {value: "D", label: "D"},
        {value: "E", label: "E"},
        {value: "F", label: "F"},
        {value: "FA", label: "FA"},
        {value: "PT", label: "PT"},
        {value: "TA", label: "TA"},
        {value: "TC", label: "TC"},
        {value: "TN", label: "TN"},
        {value: "TR", label: "TR"},
        {value: "TP", label: "TP"},
        {value: "J", label: "J"},
        {value: "LE", label: "LE"},
    ]
}
